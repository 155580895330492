import dynamic from 'next/dynamic';
import { FC } from 'react';

import { Box } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import SectionsStyle from './style';

import CoursesCardSection from 'src/components/organisms/sections/courseCard';
import { ISection, SectionType } from 'src/interfaces/course';

const TuxsaSection = dynamic(import('./tuxsa'));
const CampaignSection = dynamic(import('./campaign'));
const PopularCategorySection = dynamic(import('./popularCategory'));
const PopularInstructorSection = dynamic(import('./popularInstructorSection'));
const SubcategorySection = dynamic(import('./subcategory'));
const LearningPathSection = dynamic(import('./learningPathSection'));

const COURSE_CARD_SECTION_TYPE = [
  SectionType.Custom,
  SectionType.FreePopular,
  SectionType.Latest,
  SectionType.LearningPath,
  SectionType.Popular,
  SectionType.Recommend,
  SectionType.Bundle,
];

type SectionProps = {
  section: ISection;
  maxItems: number;
  isDisplayShowAll: boolean;
};

const Section: FC<SectionProps> = ({ section, maxItems, isDisplayShowAll }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <div ref={ref}>
      {COURSE_CARD_SECTION_TYPE.includes(section.s_type) &&
        section.courses.length > 0 &&
        (inView ? (
          <CoursesCardSection
            maxItems={maxItems}
            section={section}
            isDisplayShowAll={isDisplayShowAll}
            key={section.id}
          />
        ) : (
          <Box className="course-card-section-empty" />
        ))}

      {section.s_type === SectionType.Tuxsa &&
        (inView ? <TuxsaSection key={section.id} section={section} /> : <Box className="tuxsa-section-empty" />)}

      {section.s_type === SectionType.PopularCategory &&
        section.categories.length >= 8 &&
        (inView ? (
          <PopularCategorySection key={section.id} section={section} />
        ) : (
          <Box className="popular-category-section-empty" />
        ))}

      {section.s_type === SectionType.LearningPath && <LearningPathSection key={section.id} section={section} />}

      {section.s_type === SectionType.Campaign &&
        section.campaigns.length > 0 &&
        (inView ? <CampaignSection key={section.id} section={section} /> : <Box className="campaign-section-empty" />)}

      {section.s_type === SectionType.PopularInstructor && inView && (
        <PopularInstructorSection key={section.id} section={section} />
      )}

      {section.s_type === SectionType.Subcategory && inView && (
        <SubcategorySection key={section.id} section={section} />
      )}
    </div>
  );
};

type Props = {
  sections: ISection[];
  maxItems?: number;
  isDisplayShowAll?: boolean;
};

const Sections = ({ sections, maxItems = 4, isDisplayShowAll = false }: Props) => (
  <SectionsStyle>
    {sections?.map((section: ISection) => (
      <Section section={section} maxItems={maxItems} isDisplayShowAll={isDisplayShowAll} />
    ))}
  </SectionsStyle>
);

export default Sections;
